import React from 'react';
import { Container } from 'emotion-flex';
import { FormattedMessage } from 'react-intl';
import SEO from '../components/SEO';
import EmptyState from '../components/EmptyState';
import Link from '../components/Link';
import Button from '../components/Button';
import { withLayout } from '../components/Layout';
import { useBrowserLocale } from '../hooks';

const ButtonLink = Button.withComponent(Link);

const RestorationBad = () => {
  useBrowserLocale();
  return (
    <>
      <SEO title="Account restoration already confirmed" />
      <Container>
        <EmptyState
          title="Error, account restoration was not successful."
          text={
            <div css={{ textAlign: 'center' }}>
              <p>
                If you canceled the process in the meantime, you&#8217;ll have
                to re-start it from the app and confirm from the new email.
              </p>
              <p>
                Otherwise, please try pasting the link from the email manually
                into your browser&#8217;s address bar.
              </p>
              <p>Error type: wrong token</p>
            </div>
          }
          action={
            <ButtonLink to="https://cara.app.link/Dk8UOqildy">
              <FormattedMessage
                id="pages.email.continueToApp"
                defaultMessage="Continue to App"
              />
            </ButtonLink>
          }
          withImage
        />
      </Container>
    </>
  );
};

export default withLayout(RestorationBad, { isNewLayout: true });
